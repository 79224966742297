import React from 'react'
import { graphql } from 'gatsby'
import Hero from '../components/Hero'
import Layout from '../components/layout'
import rehypeReact from "rehype-react"
import { Img } from '@tueri/react'
import Helmet from 'react-helmet'
import Comments from 'react-disqus-comments'

const ImgNormal = ({ src, alt, style, srcSet, sizes }) => <img src={src} alt={alt} style={style} srcSet={srcSet} sizes={sizes}/>

export default function Template({ data }) {
    const { markdownRemark } = data
    const { frontmatter, htmlAst, id, fields: { slug }, excerpt } = markdownRemark

    const renderAst = new rehypeReact({
      createElement: React.createElement,
      components: { "img": Img, "img-normal": ImgNormal }
    }).Compiler

    return(
        <Layout blog>
          <Helmet
            meta={[
              { property: 'og:title', content: frontmatter.title },
              { property: 'twitter:title', content: frontmatter.title },
              { property: 'og:type', content: 'article' },
              { property: 'og:image', content: frontmatter.cover + '?w=1200&height=1200' },
              { property: 'twitter:image:src', content: frontmatter.cover + '?w=1200&height=1200' },
              { property: 'og:url', content: 'https://tueri.io' + slug },
              { property: 'og:description', content: excerpt },
              { property: 'twitter:description', content: excerpt },
              { property: 'twitter:card', content: 'summary_large_image' },
              { property: 'article:publisher', content: 'https://www.facebook.com/tueri.stack' },
              { property: 'article:author', content: 'Dane Stevens' },
              { property: 'author', content: 'Dane Stevens' },
              { property: 'article:published_time', content: frontmatter.date },
              { name: 'description', content: excerpt },
              { name: 'keywords', content: frontmatter.keywords }
            ]}
          >
            <html lang="en" prefix="og: http://ogp.me/ns#" />
            <title>Tueri - { frontmatter.title }</title>
          </Helmet>
            <Hero 
                title={frontmatter.title}
                date={frontmatter.date}
            />
            {
              renderAst(htmlAst)
            }
            <Comments
              shortname='tueri'
              identifier={ id }
              title={ frontmatter.title }
              url={`https://tueri.io${ slug }`}
            />
        </Layout>
    )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      htmlAst
      id
      fields { slug }
      excerpt
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        cover
        keywords
      }
    }
  }
`